<template>
  <div :class="{ isManucurist }" style="width: 100%">
    <TransitionGroup :name="back ? 'slide' : 'slide-reverse'">
      <div :class="['products-selector']" v-if="!selectedProduct._id" key="1">
        <h3 class="products-header">
          {{ cta.products.length }}
          {{ $t("player.atcMultiple.numberOfProducts") }}
        </h3>

        <div
          v-for="product in cta.products"
          v-bind:key="product._id"
          class="product-container"
          @click="selectProduct(product)"
        >
          <div
            class="product-image"
            :style="`background-image: url('${product.imageUrl || ''}')`"
          ></div>
          <div class="content">
            <p class="product-name">{{ decodeText(product.name) }}</p>
            <p v-if="product.variants && product.variants[0]">
              {{ displayCurrency(currency, product.variants[0].price) }}
            </p>
          </div>
          <Arrow v-if="!isManucurist" class="arrow"></Arrow>
        </div>
      </div>

      <AtcForm
        v-else-if="selectedProduct._id"
        key="2"
        :video-index="videoIndex"
        :product="selectedProduct"
        :isManucurist="isManucurist"
        @back="backClicked()"
      ></AtcForm>
    </TransitionGroup>
  </div>
</template>

<script>
import AtcForm from "./AtcForm";
import Arrow from "@/assets/arrow-horizontal.svg";
import { decodeText, displayCurrency } from "@/utils/utils";
import { mapGetters } from "vuex";

export default {
  name: "AtcMultipleProducts",

  components: {
    AtcForm,
    Arrow,
  },

  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectedProduct: {},
      back: false,
      isManucurist: false,
      displayCurrency,
      decodeText,
    };
  },
  created() {
    if ((this.cta?.products && this.cta?.products.length === 1) ?? false) {
      this.selectedProduct = this.cta.products[0];
    }
    this.manucuristCheck();
  },

  computed: {
    ...mapGetters("playerSite", ["currency"]),
    ...mapGetters("videos", ["getCtaByIndex"]),
    cta() {
      return this.getCtaByIndex(this.videoIndex);
    },
  },
  watch: {
    cta: {
      handler: function (newVal) {
        if (
          newVal.products &&
          newVal.products.length === 1 &&
          !this.selectedProduct._id
        ) {
          this.selectedProduct = newVal.products[0];
        }
      },
      deep: true,
    },
  },
  methods: {
    manucuristCheck() {
      this.cta.products.forEach((product) => {
        if (
          product.siteId === "81b984f5-5bb6-437b-9c00-50f028c40076" ||
          product.siteId === "39f362e5-c3f4-4eba-bdfd-2b9be89f827d" ||
          // test player site id
          product.siteId === "874f71d2-a54d-4830-9941-f4e30c237560"
        ) {
          this.isManucurist = true;
        }
      });
    },
    selectProduct(product) {
      this.back = false;
      this.selectedProduct = product;
    },
    backClicked() {
      this.back = true;
      this.selectedProduct = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.products-selector {
  padding-bottom: 5px;
}
.products-header {
  padding-top: 30px;
  margin: 0px 15px;
  font-weight: 600;
  font-size: 17px;
}

.product-container {
  display: flex;
  cursor: pointer;
  margin: 30px 10px;
  border-radius: 3px;
  align-items: center;
  outline: 1px solid #c4c4c4;
  .product-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 21px;
    max-height: 48px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .arrow {
    margin-left: auto;
    margin-right: 15px;
  }
}
.content {
  margin-left: 10px;
  width: 50%;
  p {
    margin-bottom: 5px;
  }
}

.product-container:hover {
  box-shadow: 0px 0px 6px 0.5px rgba(0, 0, 0, 0.2);
}
.product-image {
  height: 88px;
  min-width: 88px;
  background-size: cover;
  border-radius: 3px 0px 0px 3px;
}
.slide-enter-active,
.slide-reverse-enter-active {
  transition: all 0.5s;
}
.slide-enter {
  transform: translateX(-100%);
}
.slide-reverse-enter {
  transform: translateX(100%);
}
</style>
